<template>
  <div class="project-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebarCampaign />
    <OrganismMenuBar>
      <div class="hidden-xs">
        <router-link
          active-class="active"
          class="sidebar-link"
          tag="a"
          v-for="(section, i) in sections"
          v-if="section.enabled && section.menuLabel"
          :to="{
            name: pageName,
            params: { projectId: activeProject },
            hash: `#${section.hashAnchor}`,
          }"
          :key="i"
          @click.native="hashChecker(`#${section.hashAnchor}`)"
        >
          {{ section.menuLabel }}
        </router-link>
      </div>
    </OrganismMenuBar>
    <OrganismSidebar>
      <div class="menu">
        <router-link
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: 'Home' }"
          >Home</router-link
        >
        <div v-if="containsSpaceSlug">
          <router-link
            active-class="active"
            class="sidebar-link"
            tag="a"
            v-for="(section, i) in sections"
            v-if="section.enabled && section.menuLabel"
            :to="{
              name: 'SpecificSpace',
              params: { projectId: activeProject, spaceId: activeSpace },
              hash: `#${section.hashAnchor}`,
            }"
            :key="i"
            @click.native="hashChecker(`#${section.hashAnchor}`)"
          >
            {{ section.menuLabel }}
          </router-link>
        </div>
        <div v-else>
          <router-link
            active-class="active"
            class="sidebar-link"
            tag="a"
            v-for="(section, i) in sections"
            v-if="section.enabled && section.menuLabel"
            :to="{
              name: pageName,
              params: { projectId: activeProject },
              hash: `#${section.hashAnchor}`,
            }"
            :key="i"
            @click.native="hashChecker(`#${section.hashAnchor}`)"
          >
            {{ section.menuLabel }}
          </router-link>
        </div>
        <router-link
          v-for="(page, i) in staticPages"
          :key="i"
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: page.name, params: {} }"
          >{{ page.menuLabel || page.name }}</router-link
        >
        <div v-for="(page, i) in projectSpecificStaticPages" :key="i">
          <router-link
            v-if="!externalLink(page.path)"
            active-class="active"
            class="sidebar-link"
            tag="a"
            :to="{ name: page.name, params: { projectId: activeProject } }"
            >{{ page.menuLabel || page.name }}</router-link
          >
          <a v-else class="sidebar-link" :href="page.path">{{
            page.menuLabel || page.name
          }}</a>
        </div>
        <OrganismProjectLinks />
      </div>
      <div class="social-links">
        <a :href="instagramUrl"
          ><AtomIcon icon="instagram" :size="24" color="#000"
        /></a>
        <a :href="linkedinUrl"
          ><AtomIcon icon="linkedin-squared" :size="24" color="#000"
        /></a>
        <a :href="facebookUrl"
          ><AtomIcon icon="facebook-squared" :size="24" color="#000"
        /></a>
      </div>
    </OrganismSidebar>
  </div>
</template>

<script>
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import { smoothScrollToTargetId } from "../helpers/util";
import OrganismSidebarCampaign from "@/components/organisms/OrganismSidebarCampaign";
import OrganismMenuBar from "@/components/organisms/OrganismMenuBar";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import OrganismProjectLinks from "../components/organisms/OrganismProjectsLinks";

export default {
  name: "ProjectTemplate",
  components: {
    OrganismProjectLinks,
    AtomIcon,
    OrganismMenuBar,
    OrganismSidebarCampaign,
    OrganismSidebar,
  },
  data() {
    return {
      sections: [],
    };
  },
  computed: {
    pageName() {
      const { name } = this.$route;
      return name;
    },
    projects() {
      return this.$store.state?.project?.projects || [];
    },
    landingSections() {
      return this.$store.state.base.meta.landing.sections;
    },
    sectionsData() {
      const { page } = this.$route.meta;
      return page ? page.sections : [];
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    activeSpace() {
      return this.$route.params.spaceId;
    },
    containsSpaceSlug() {
      return this.$route.params.spaceId !== undefined;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    projectSpecificStaticPages() {
      return this.generalConfig.projectStaticPages || [];
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections();
      },
    },
  },
  mounted() {
    this.sortSections();
    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", self.smoothScroll);
      });
    if (this.$route.hash !== "") {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      if (document.querySelector("#building") && this.containsSpaceSlug) {
        document.querySelector("#building").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  },
  updated() {
    if (this.$route.hash !== "") {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      if (document.querySelector("#building") && this.containsSpaceSlug) {
        document.querySelector("#building").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  },
  beforeDestroy() {
    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.removeEventListener("click", self.smoothScroll);
      });
  },
  methods: {
    externalLink(url) {
      if (url && url.includes("://")) {
        return true;
      }
      return false;
    },
    sortSections() {
      let sectionsData = [...this.sectionsData].map((s) => {
        const clone = { ...s };
        if (this.$route.name === "SpecificSpace" && clone.type === "Building")
          clone.order_no = 1;
        if (
          this.$route.name === "SpecificSpace" &&
          ["home", "hero"].includes(clone.hashAnchor)
        )
          clone.order_no = 2;
        return clone;
      });
      if (this.$route.name === "SpecificSpace") {
        sectionsData = sectionsData.filter(
          (s) => !["home", "hero"].includes(s.hashAnchor)
        );
      }
      this.sections = sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1;
        }
        if (a.order_no > b.order_no) {
          return 1;
        }
        return 0;
      });
    },
    smoothScroll(e) {
      e.preventDefault();
      document
        .querySelector(`#${e.target.getAttribute("href").split("#")[1]}`)
        .scrollIntoView({
          behavior: "smooth",
        });

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]');
      links.forEach((link) => {
        const activeLink = e.path[0];
        link.classList.remove("active");
        if (link === activeLink && !activeLink.classList.contains("active")) {
          link.classList.add("active");
        }
      });
    },
    hashChecker(target) {
      // fix weird behaviour for scrolling event on second router-link click
      if (target === window.location.hash) {
        smoothScrollToTargetId(target);
      }
    }
  },
};
</script>

<style lang="scss">
  .project-template {
    display: block;
    .organism-menu-right {
      .btn-general {
        background: transparent;
        color: $black;
        border: 1px solid $main-color;
        &:hover, &.active {
          background: $main-color;
          color: $white;
        }
      }
      .phone-header-parent {
        .phone-header {
          border-color: $main-color-opacity-01;
          .icon {
            color: $black !important;
          }
        }
        &:hover {
          .phone-header {
            .icon {
              color: $white !important;
            }
          }
        }
      }
    }
  }
</style>
